<template>
  <div>
    <h1>We'd love to hear from you!</h1>

    <h3>Want to feature your recipes?</h3>
    <p>
      Our goal is to include as many <strong>vegan recipes</strong> on our platform as possible. 
      If you have a website or blog featuring plant-based recipes, and you want us to include them on our website, we'd love to hear from you.
    </p>
    <p>
      We do not show preparation instructions on our platform and rather invite visitors to read those on the original source. We always provide clear and very visible links back to the original website. We want to encourage people to read from the original source. 
    </p>

    <h3>Comments or remarks on a recipe?</h3>
    <p>
      We have optimized our <router-link to="/approach">automated recipe parsers</router-link>, but small discrepancies may still occur. 
      Please notify us if you find something that doesn't make sence or seems incorrect by contacting us (don't forget to include a link to the page where you spotted the anomaly). 
      We will investigate and correct any mistakes.
    </p>

    <h3>Contact Us</h3>
    <p>Leave a message using form below, or simply email us at <a href="mailto:hello@veganfoodvalues.com">hello@veganfoodvalues.com</a>.</p>
    <v-form lazy-validation ref="form" action="https://formspree.io/mayenrlz" method="POST">

      <v-text-field
        v-model="name"
        :rules="nameRules"
        label="Your Name"
        name="name"
        required
      ></v-text-field>

      <v-text-field
        v-model="email"
        :rules="emailRules"
        label="E-mail"
        name="_replyto"
        type="email"
        required
      ></v-text-field>

      <v-textarea v-model="comments" required label="Your message:" name="comments"></v-textarea>
    
      <v-btn class="primary" type="submit">Submit</v-btn>
    </v-form>

  </div>
</template>


<script>

export default {
  name: "Contact",
  props: {
  },
  data: function() {
    return {
      valid: true,
      name: '',
      nameRules: [
        v => !!v || 'Name is required',
      ],
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      comments: ''
    };
  },
  created() {
  },
  mounted() {
  },
  methods: {
    submit () {
        if (this.$refs.form.validate()) {
        }
      }
  },
  watch: {
  }
};
</script>

<style scoped>
</style>